import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
         <h1>Zplore is coming</h1>...
         <p>are you ready?</p>
        </p>
      </header>
    </div>
  );
}

export default App;
